
//  Utility Classes
//--------------------------------------------------------------------------------------------------------------
.raised {
    background: $white;
    border: 10px solid $white;
    box-shadow: 6px 6px 13px 0 rgba(0,0,0,0.03), 0 40px 100px -50px rgba(0,0,0,0.40);
}

//img.circle { border-radius: 50%; }

.inverse {
    color: $white !important;
    &.lead { color: rgba(255,255,255,0.75) !important; }
}

//  Font Color & Background Inverter
.inverter {
    color: white !important;
    transition: 0.44s all;

    svg path, p { transition: 0.44s all; }

    // Base :hover styles
    &:hover {
        background: $white !important;
        margin-top: -17.5rem !important;
        box-shadow: 0 120px 90px 0 rgba(0, 0, 0, 0.04), 0 30px 45px 0 rgba(0, 0, 0, 0.09);
    }

    // Color Specifics
    &.seafoam:hover, &.seafoam:hover p {
        color: $seafoam !important;
        svg path { fill: $seafoam !important; }
    }
    &.azure:hover, &.azure:hover p {
        color: $azure !important;
        svg path { fill: $azure !important; }
    }
    &.wildberry:hover, &.wildberry:hover p {
        color: $wildberry !important;
        svg path { fill: $wildberry !important; }
    }
}

// Lettering Treatments
.letter-spaced { letter-spacing: 4px; font-weight: bolder; }
.double-spaced { line-height: 1.9; }

// Custom Margin Classes
.mt-6 { margin-top: 8rem !important; }
.mb-6 { margin-bottom: 8rem; }
.my-6 { margin-top: 8rem; margin-bottom: 8rem; }

.ml-6 { margin-left: 8rem; }
.mr-6 { margin-right: 8rem; }
.mx-6 { margin-left: 8rem; margin-right: 8rem; }

// Custom Padding Classes
.pt-6 { padding-top: 8rem; }
.pb-6 { padding-bottom: 8rem; }
.py-6 { padding-top: 8rem; padding-bottom: 8rem; }

.pl-6 { padding-left: 8rem; }
.pr-6 { padding-right: 8rem; }
.px-6 { padding-left: 8rem; padding-right: 8rem; }

.tag {
    font-family: $europa;
    font-size: $tiny-size;
    font-weight: bolder;
    letter-spacing: 1.69px;
    color: $white;
    text-transform: uppercase;
    padding: 6px 14px;
    background: $azure;
    transition: 0.44s all;

    &:hover { text-decoration: none; color: $white; }

    // Class Variants
    &.magento {
        background: $orange;
        &:hover { background: darken($orange, 10%); }
    }
    &.machine-learning {
        background: $seafoam;
        &:hover { background: darken($seafoam, 10%); }
    }
    &.shopify {
        background: $green;
        &:hover { background: darken($green, 10%); }
    }
    &.wordpress {
        background: $blue;
        &:hover { background: darken($blue, 10%); }
    }
    &.laravel {
        background: $salmon;
        &:hover { background: darken($salmon, 10%); }
    }
}