$padding-base: 15px;
$color-body: #fff;
$color-border: #ddd;
$color-link: #0275d8;
$border-radius: 0;

.nav-tabs {
    min-height: 735px; // prevents size jump when clicking Integrations
    background: $light-gray;
    display: flex;
    flex-direction: column;

    &--vertical {
        border-bottom: none;
        border-right: 1px solid $color-border;
        display: block;

    }

    &--left {
        margin: 0;

        .nav-item {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
        }

        .nav-item + .nav-item {
            margin-top: 1px;
        }

        .nav-link {
            padding: 2rem;
            transition: all .25s;
            white-space: nowrap;
            border-left: none;
            flex-grow: 1;
            word-break:break-word;
            white-space: normal;

            h4 {
                color: $text-gray;
                transition: all .25s;
            }

            p {
                margin-bottom: 0;
                display: block;
                word-break:break-word;
                white-space: normal;
                transition: all .25s;
            }

            &:hover {
                background-color: #f9f9f9;
                border-color: transparent;

                h4 { color: $navy; }
            }
            &:first-child { border-top: none !important; }
            &:last-child { border-bottom: 1px solid $border-gray; }
        }

        .nav-link.active {
            border-bottom-color: $color-border;
            border-right-color: $color-body;
            border-radius: 0;
            margin-right: -1px;

            h4 { color: $navy; }

            &:hover {
                background-color: lighten($light-gray, 10%);
                // border-color: $color-link $color-body $color-link $color-link;
            }
        }
    }
}

.tools {
    .block {
        text-align: center;
        padding: 1rem 0;

        &.laravel {
            color: $salmon;
            transition: $transition-base;
            &:hover {
                color: darken($salmon, 10%);
            }
        }
        &.magento {
            color: $orange;
            transition: $transition-base;
            &:hover {
                color: #ef672f;
            }
        }
        &.react {
            color: #61dafb;
            transition: $transition-base;
            &:hover {
                color: darken(#61dafb, 10%);
            }
        }
        &.wordpress {
            color: #4ca6cf;
            transition: $transition-base;
            &:hover {
                color: darken(#4ca6cf, 10%);
            }
        }
        &.statamic {
            color: #9940e3;
            transition: $transition-base;
            &:hover {
                color: darken(#9940e3, 10%);
            }
        }
        &.stripe {
            color: #00afe1;
            transition: $transition-base;
            &:hover {
                color: darken(#00afe1, 10%);
            }
        }
        &.paypal {
            color: #009cde;
            transition: $transition-base;
            &:hover {
                color: darken(#009cde, 10%);
            }
        }
        &.facebook {
            color: #3B5998;
            transition: $transition-base;
            &:hover {
                color: darken(#3B5998, 10%);
            }
        }
        &.algolia {
            color: #5468ff;
            transition: $transition-base;
            &:hover {
                color: darken(#5468ff, 10%);
            }
        }
        &.salesforce {
            color: #1798c1;
            transition: $transition-base;
            &:hover {
                color: darken(#1798c1, 10%);
            }
        }
        &.quickbooks{
            color: #2CA01C;
            transition: $transition-base;
            &:hover {
                color: darken(#2CA01C, 10%);
            }
        }
        &.ups{
            color: #FCB900;
            transition: $transition-base;
            &:hover {
                color: darken(#FCB900, 10%);
            }
        }
        &.php{
            color: #8892be;
            transition: $transition-base;
            &:hover {
                color: darken(#8892be, 10%);
            }
        }
        &.nodejs{
            color: #6cc24a;
            transition: $transition-base;
            &:hover {
                color: darken(#6cc24a, 10%);
            }
        }
        &.js{
            color: #f7df1e;
            transition: $transition-base;
            &:hover {
                color: darken(#f7df1e, 10%);
            }
        }
        &.sass{
            color: #CD6799;
            transition: $transition-base;
            &:hover {
                color: darken(#CD6799, 10%);
            }
        }
        &.mongo{
            color: #3FA037;
            transition: $transition-base;
            &:hover {
                color: darken(#3FA037, 10%);
            }
        }
        &.mysql{
            color: #00758F;
            transition: $transition-base;
            &:hover {
                color: darken(#00758F, 10%);
            }
        }
        &.redis{
            color: #D82C20;
            transition: $transition-base;
            &:hover {
                color: darken(#D82C20, 10%);
            }
        }
        &.digitalocean{
            color: #008BCF;
            transition: $transition-base;
            &:hover {
                color: darken(#008BCF, 10%);
            }
        }

        img {
            height: 60px;
            width: auto;
            max-width: 100px;
        }

        i, svg {
            font-size: $display4-size;
        }
        h4 {
            font-size: $lead-size;
            font-family: $europa;
            margin: 1.5rem 0 0;
        }
    }
}