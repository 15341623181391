@import url("https://use.typekit.net/oze4hgd.css");

// Variables
// --------------------------------------------------------------------------------------------------------------
@import "variables";
@import "bootstrap/bootstrap";
@import "fontawesome/fontawesome";

// Components
// --------------------------------------------------------------------------------------------------------------

@import "./components/navs";
@import "./components/typography";
@import "./components/utility";
@import "./components/footer";
@import "./components/browser";
@import "./components/team";
@import "./components/research";
@import "./components/contact";

// Pages
// --------------------------------------------------------------------------------------------------------------

@import "./pages/home";
@import "./pages/clients";


//  Desktop Styles
//--------------------------------------------------------------------------------------------------------------

.main-hero:not(.home-page) {
    background: white;
    margin: 5rem 0 0 0;
}

article {
    h1, h2, h3 {
        margin: 2rem 0 1rem 0;
    }

    h4, h5, h6 {
        margin: 1rem 0 0.5rem 0;
    }

    img {
        width: 100%;
        margin: 2rem 0;
    }

    img.shadow {
        box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.3);
    }

    pre[class^="language-"] {
        border-radius: 10px;
        padding: 1rem;
        background-color: #242929;
    }

    code:not([class^="language-"]) {
        color: #01ab85;
    }

    code {
        color: #95d8cb;
    }
}


//  Mobile Styles
//--------------------------------------------------------------------------------------------------------------
@media only screen and (max-width: 992px) {
    .main-hero:not(.home-page) {
        margin: 4rem 0 0 0;
    }

    img.img-fluid.raised {
        margin-bottom: 50px;
    }

    .mt-6 {
        margin-top: 3rem !important;
    }

    #primary-nav {
        padding: 15px;
    }

}

@media only screen and (max-width: 768px) {

    .browser { display: none; }

    .py-6 {
        padding: 2rem 0;
    }

    #primary-nav {
        .nav-item {
        padding: 0;
        margin-left: 0rem;
        }
    }

    .navbar-toggler { border-color: rgba(255, 255, 255, 0.1) !important; border-radius: 0; }

    #nav-wrap {
        //background: rgba(25, 41, 71, 0.95);

        #primary-nav { padding: 1rem 0; }
    }

    .main-hero:not(.home-page) {
        margin: 3rem 0 0 0;
    }

    #services {
        padding-top: 3rem;

        .container-fluid {
                text-align: center;
            margin-bottom: 6rem;
            padding: 0px 1rem 4rem !important;
        }

        .top-block { height: 280px; }

        .top-block.inverter {
            margin-top: 0 !important;
            margin-bottom: 3rem;

            &:hover { margin-top: 0; margin-bottom: 3rem; }
        }
    }

    #team .team-row,
    .white-page #clientele .client-row {
        padding: 0;
        margin-bottom: 0;

        .col { margin-bottom: 3rem; margin-top: 0; }
    }

    #contact {
        .connect-info a {
            color: white;
            font-size: $h5;
            vertical-align: bottom;
            font-family: $europa;
            width: 100%;
            display: block;
            margin-bottom: 1rem;
            margin-right: 0 !important;

            i { font-size: $h4; }

            &:hover { text-decoration: none; }
        }
    }

    #research .left article.square {
        margin-right: 0;
    }

    #research .right article.square {
        margin-left: 0;
    }

    article.square {
        &:before {
            display: none;
        }
    }
}
