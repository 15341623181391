// Baselines
html { -webkit-font-smoothing: antialiased; }

h1, h2, h3, h4, h5, h6 {
    font-family: "europa";
    font-weight: 300;
}
h1 { font-size: $h1; }
h2 { font-size: $h2; }
h3 { font-size: $h3; }
h4 { font-size: $h4; }
h5 { font-size: $h5; }
h6 { font-size: $h6; }

p {
    font-family: $macho;
    font-size: $normal-size;
    color: $text-gray;
}