/*!
 * Bootstrap v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@import "bootstrap/scss/functions";
@import "variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/code";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/input-group";
@import "bootstrap/scss/custom-forms";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/card";
@import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/pagination";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/jumbotron";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/progress";
@import "bootstrap/scss/media";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/popover";
@import "bootstrap/scss/carousel";
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/print";

//  Bootstrap Overrides
//--------------------------------------------------------------------------------------------------------------
.lead {
    font-family: $europa;
    color: $seafoam;
    text-transform: uppercase;
    font-size: $lead-size;
    letter-spacing: 4px;
    font-weight: bolder;
}

// Base BTN Overrides
.btn {
    border-radius: 0 !important;
    font-size: 19px;
    font-family: $europa;
    font-weight: bolder;
    text-transform: uppercase;
    padding: 16px 52px;
}
// Seafoam
.btn-green { background: $seafoam; border: none; color: $white;
    &:hover { background: darken($seafoam,10%); color: $white; }
}
// Azure
.btn-blue, .btn-primary { background: $azure; border: none; color: $white;
    &:hover { background: darken($azure,10%); color: $white; }
}
// Wildberry
.btn-purple { background: $wildberry; border: none; color: $white;
    &:hover { background: darken($wildberry,10%); color: $white; }
}
