#contact {
    background: url($theme_root + '/img/down-caret.svg'), $navy;
    background-repeat: no-repeat;
    background-position: top center;
    padding: 6rem 0;

    .connect-info a {
        color: white;
        font-size: $h4;
        vertical-align: bottom;
        font-family: $europa;

        i { font-size: $h3; }

        &:hover { text-decoration: none; }
    }

    input, textarea {
        border-radius: 0;
        border: none !important;
        padding: 1.25rem;
        font-family: $macho;
    }

    .form-row > [class*="col-"] {  padding-left: 15px; padding-right: 15px; }
    .budget {
        display: none;
    }
}