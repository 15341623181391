$theme_root: '/site/themes/ameronix';

// Thematic Colors
$white: #FFFFFF;
$seafoam: #38C2A3;
$azure: #67A9F7;
$wildberry: #C677E6;
$navy: #192947;
//
// Tag Colors
$orange: #F3935E;      // Magento
$blue: #239CCD;         // WordPress
$green: #83AB55;       // Shopify
$salmon: #FC837B;    // Laravel
//
// Grayscale
$text-gray: #7a7a7a;
$light-gray: #f4f4f4;
$border-gray: #E0E0E0;
//
// Fonts
$europa: "europa", Helvetica, sans-serif;
$macho: "macho", Helvetica, sans-serif;
//
// Text
$lead-size: 15px;
$normal-size: 17px;
$tiny-size: 11px;

$font-size-base: 1rem !default;
$h1: $font-size-base * 3 !default;
$h2: 47px;
$h3: 33px;
$h4: 25px;
$h5: 19px;
$h6: $lead-size;
