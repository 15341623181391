//  Clients Page
//--------------------------------------------------------------------------------------------------------------
.clients-page, .white-page {

    #primary-nav {

        .nav-item {
            padding: 0;
            margin-left: 3.5rem;

            .nav-link {
                padding: 2.1rem 0;
                color: $navy !important;
                text-transform: uppercase;
                font-family: $europa;
                font-weight: bolder;
                letter-spacing: 4px;
                font-size: $lead-size;
                // Offset for 1px active border
                margin-bottom: -1px;
                margin-top: 1px;
                border-bottom: 1px solid transparent;
                transition: 0.2s all;

                &:last-child { padding-right: 0; }

                &:hover {
                    border-bottom: 1px solid $azure;
                }
            }

            // Active Link Border
            &.active .nav-link {
                border-bottom: 1px solid $seafoam;
            }
        }
        &.border-light { border-color: rgba(0,0,0,0.12) !important; }
    }

    #clientele {
        .client-row {
            padding: 0 6rem;

            .col-12 {
                padding: 0 2rem;
                position: relative;
            perspective: 800px;
                margin-bottom: 5rem;

            }
        }



        #card {
          width: 100%;
          height: 100%;
          position: relative;
          transform-style: preserve-3d;
          transition: transform 0.88s;
            display: flex;
            min-height: 440px;
            height: 440px;
            justify-content: space-around;
            cursor: pointer;

            .img-container { min-height: 220px; max-height: 220px; display: flex; align-content: center; justify-content: center; }

            figure {
              margin: 0;
              display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 0 2rem;
              position: absolute;
                background: $light-gray;
              width: 100%;
              height: 100%;
              backface-visibility: hidden;
            }
            .back {
                padding: 0 0;
                background: $navy;
                transform: rotateY( -180deg );

                p { color: white !important; }
                p:first-child { padding: 0 3rem; }

                &.green { background: $green; }
                &.dark { background: $dark; }
                &.orange { background: $orange; }
                &.seafoam { background: $seafoam; }
                &.azure { background: $azure; }
            }
            &.flipped {
              transform: rotateY( -180deg );
            }
        }
    }
}

.curl {
  width:0px;
  height:0px;
  position: absolute;
  bottom: 0;
  right: 0;
  background :
          linear-gradient(
                          -45deg,
                          #fff,
                          #fff 45%,
                          #fff 51%,
                          darken($green, 10%) 50%,
                          darken($green, 9%) 56%,
                          darken($green, 7.5%) 62%,
                          darken($green, 5%) 80%,
                          $green 100%
          );
  transition: all .5s ease;
}
.curl:before,
.curl:after {
  content: '';
  position: absolute;
  z-index: -1;
  left: 12.5%;
  bottom: 5.8%;
  width: 70%;
  max-width: 300px;
  max-height: 100px;
  height: 55%;
  //box-shadow: 0 0px 15px rgba(0, 0, 0, .3);
  transform: skew(-10deg) rotate(-6deg);
}
.curl:after {
  left: auto;
  right: 5.8%;
  bottom: auto;
  top: 14.16%;
  transform: skew(-15deg) rotate(-84deg);
}

figure.front:hover {
  .curl {
    width: 35px;
    height: 35px;
  }
}

@media screen and (max-width: 992px) {
  #primary-nav {
    .nav-item {
      margin-left: 0rem !important;
    }
  }
}

@media screen and (max-width: 500px) {
  .client-row {
    padding: 0;
  }
}