#research {
    position: relative;
    background: url($theme_root + '/img/center-line.svg') repeat-y, $seafoam;
    background-position: center;
    padding: 6rem 0 0;

    &:before {
        position: absolute;
        filter: saturate(0.4) brightness(1.3);
        content: "";
        background: url($theme_root + '/img/down-caret.svg');
        background-repeat: no-repeat;
        background-position: top center;
        top: 0;
        height: 100%;
        width: 100%;
    }

    header {
        background: $seafoam;
        padding: 3rem 0;
        margin-bottom: 4rem;
    }

    article.square {
        background-size: cover;
        min-height: 460px;
        display: flex;
        flex-direction: column-reverse;
        color: $white;
        border: 8px solid $white;
        padding: 2rem;
        z-index: 15;
        position: relative;

        h3, p.desc, p.mb-0 { z-index: 10; }
        h3 { 
            color: $white;
            font-weight: bolder; 
            order: 3;

            a {
                color: $white;
                text-decoration: none;
            }
        }
        p.desc { order: 2; color: rgba(255,255,255,0.66); font-size: $lead-size; margin-bottom: 1.5rem; margin-top: 0.35rem; }
        p.mb-0 { order: 1; }

        &:after {
            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#36537d+0,192947+70,192947+100&0.66+0,1+88,1+100 */
            background: -moz-linear-gradient(top, rgba(54,83,125,0.66) 0%, rgba(25,41,71,0.93) 70%, rgba(25,41,71,1) 88%, rgba(25,41,71,1) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top, rgba(54,83,125,0.66) 0%,rgba(25,41,71,0.93) 70%,rgba(25,41,71,1) 88%,rgba(25,41,71,1) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom, rgba(54,83,125,0.66) 0%,rgba(25,41,71,0.93) 70%,rgba(25,41,71,1) 88%,rgba(25,41,71,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a836537d', endColorstr='#192947',GradientType=0 ); /* IE6-9 */
        content: "";
        height: 100%;
        position: absolute;
        width: 100%;
            bottom: 0;
            left: 0;
        }
    }

    .right article.square {
        margin-left: 4rem;
        margin-top: 6rem;

        &:before {
            content: "";
        width: 91px;
        height: 59px;
        z-index: 1;
        position: absolute;
        top: 5rem;
        left: -99px;
        background: url($theme_root + '/img/right-marker.svg');
            background-position: center left;
        }
    }
    .left article.square {
        margin-right: 4rem;
        margin-bottom: 6rem;

        &:last-child {
            margin-bottom: 0;
        }

        &:before {
            content: "";
            width: 91px;
            height: 59px;
            z-index: 1;
            position: absolute;
            top: 5rem;
            right: -99px;
            background: url($theme_root + '/img/left-marker.svg');
            background-position: center right;
        }
    }

    // Breaks Vertical Repeating Line before bottom
    .line-breaker {
        display: block;
        background: $seafoam;
        height: 6rem;
        width: 100%;
        margin-top: 4rem;
    }
}