//  Page Sections
//--------------------------------------------------------------------------------------------------------------

body:not(.white-page) {
    #nav-wrap {
        background: rgba(25,41,71,0.8);
    }
}

.video-container {
    position: relative;
    overflow: hidden;

    .video-insert {
        position: absolute;
        min-width: 100%;
        min-height: 100%;
        z-index: -100;
    }
}

.main-hero.home-page {
    background: rgba(25,41,71,0.8);
    min-height: 750px;
    margin-bottom: 0;

    .lead { margin-top: 10.5rem; }
}

#services {
    min-height: 200px;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,f8f8f8+95,eeeeee+100 */
    background: #ffffff; /* Old browsers */
    background: -moz-linear-gradient(top, #ffffff 0%, #f8f8f8 95%, #eeeeee 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #ffffff 0%,#f8f8f8 95%,#eeeeee 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #ffffff 0%,#f8f8f8 95%,#eeeeee 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#eeeeee',GradientType=0 ); /* IE6-9 */

    .top-block {
        height: 350px;
        padding: 1rem;
        // Set Flex Direction
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        margin-top: -16rem;

        p { color: $white; }

        h4 { font-weight: bolder; letter-spacing: 4px; }
        // p { font-family: $europa; font-weight: 300; }

        &.seafoam { background: $seafoam; }
        &.azure { background: $azure; }
        &.wildberry { background: $wildberry; }
    }

    .container-fluid { padding: 0 6rem; margin-bottom: 6rem; }

    // Browser Window
    .browser {
        background: $white;
        border: 1px solid $border-gray;
        box-shadow: 6px 6px 13px 0 rgba(0,0,0,0.03), 0 40px 100px -50px rgba(0,0,0,0.40);
        margin-bottom: -6rem;

        .browser-top {
            display: flex;
            height: 70px;
            padding: 0 30px;
            border-bottom: 1px solid $border-gray;
            flex-direction: column;
            align-content: center;
            justify-content: center;

            .row {
                // Set Flex Direction
                display: flex;
                flex-direction: row;
                align-content: start;
                justify-content: center;

                .col-6 { display: flex; }
                .col-6:last-child { justify-content: flex-end; color: $seafoam !important; }
            }
        }

        .tab-content {
            padding: 2rem 2.5rem;
        }
    }
}

#featured-client {
    .lead { color: $azure; }
}
