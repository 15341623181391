// Primary Nav
#primary-nav {
    padding: 0;

    .navbar-toggler-icon {
        filter: invert(100%);
    }

    .nav-item {
        padding: 0;
        margin-left: 3.5rem;

        .nav-link {
            padding: 2.1rem 0;
            color: $white !important;
            text-transform: uppercase;
            font-family: $europa;
            font-weight: bolder;
            letter-spacing: 4px;
            font-size: $lead-size;
            // Offset for 1px active border
            margin-bottom: -1px;
            margin-top: 1px;
            border-bottom: 1px solid transparent;
            transition: 0.2s all;

            &:last-child { padding-right: 0; }

            &:hover {
                border-bottom: 1px solid $azure;
            }
        }

        // Active Link Border
        &.active .nav-link {
            border-bottom: 1px solid $seafoam;
        }
    }
    &.border-light { border-color: rgba(255,255,255,0.1) !important; }
}

.white-page #primary-nav .navbar-toggler-icon {
    filter: none;
}

#nav-wrap { background: none; }
