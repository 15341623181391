#team {
    .team-row {
        padding: 0 6rem;
        margin-bottom: 6rem;

    }
    .team-card {
        padding: 4.5rem 2rem 4rem;
        background: $light-gray;
        transition: 0.44s all;
        margin-bottom: 1rem;

        &:hover {
            margin-top: -1rem;
            margin-bottom: 0;
            background: $white;
            box-shadow: 6px 6px 25px 0 rgba(0,0,0,0.03), 0 50px 100px -50px rgba(0,0,0,0.40);
        }

        h3 {
            font-size: $h5-font-size;
            font-weight: bolder;
        }

        .email, .phone {
            font-size: 0.85rem;
        }
    }
}